import { LoginPage } from 'components/Login'
import React, { Fragment } from 'react'

const Login = () => {
	return (
		<Fragment>
			<LoginPage
				handleLogin={(_data: any) => {
					return
				}}
			></LoginPage>
		</Fragment>
	)
}

export default Login
